:root {
    --aos-distance: 30px;
}

body[data-aos-duration="50"] [data-aos], [data-aos][data-aos][data-aos-duration="50"] {
    transition-duration: 50ms
}

body[data-aos-delay="50"] [data-aos], [data-aos][data-aos][data-aos-delay="50"] {
    transition-delay: 0s
}

body[data-aos-delay="50"] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay="50"].aos-animate {
    transition-delay: 50ms
}

body[data-aos-duration="100"] [data-aos], [data-aos][data-aos][data-aos-duration="100"] {
    transition-duration: 100ms
}

body[data-aos-delay="100"] [data-aos], [data-aos][data-aos][data-aos-delay="100"] {
    transition-delay: 0s
}

body[data-aos-delay="100"] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay="100"].aos-animate {
    transition-delay: 100ms
}

body[data-aos-duration="150"] [data-aos], [data-aos][data-aos][data-aos-duration="150"] {
    transition-duration: 150ms
}

body[data-aos-delay="150"] [data-aos], [data-aos][data-aos][data-aos-delay="150"] {
    transition-delay: 0s
}

body[data-aos-delay="150"] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay="150"].aos-animate {
    transition-delay: 150ms
}

body[data-aos-duration="200"] [data-aos], [data-aos][data-aos][data-aos-duration="200"] {
    transition-duration: 200ms
}

body[data-aos-delay="200"] [data-aos], [data-aos][data-aos][data-aos-delay="200"] {
    transition-delay: 0s
}

body[data-aos-delay="200"] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay="200"].aos-animate {
    transition-delay: 200ms
}

body[data-aos-duration="250"] [data-aos], [data-aos][data-aos][data-aos-duration="250"] {
    transition-duration: 250ms
}

body[data-aos-delay="250"] [data-aos], [data-aos][data-aos][data-aos-delay="250"] {
    transition-delay: 0s
}

body[data-aos-delay="250"] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay="250"].aos-animate {
    transition-delay: 250ms
}

body[data-aos-duration="300"] [data-aos], [data-aos][data-aos][data-aos-duration="300"] {
    transition-duration: 300ms
}

body[data-aos-delay="300"] [data-aos], [data-aos][data-aos][data-aos-delay="300"] {
    transition-delay: 0s
}

body[data-aos-delay="300"] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay="300"].aos-animate {
    transition-delay: 300ms
}

body[data-aos-duration="350"] [data-aos], [data-aos][data-aos][data-aos-duration="350"] {
    transition-duration: 350ms
}

body[data-aos-delay="350"] [data-aos], [data-aos][data-aos][data-aos-delay="350"] {
    transition-delay: 0s
}

body[data-aos-delay="350"] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay="350"].aos-animate {
    transition-delay: 350ms
}

body[data-aos-duration="400"] [data-aos], [data-aos][data-aos][data-aos-duration="400"] {
    transition-duration: 400ms
}

body[data-aos-delay="400"] [data-aos], [data-aos][data-aos][data-aos-delay="400"] {
    transition-delay: 0s
}

body[data-aos-delay="400"] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay="400"].aos-animate {
    transition-delay: 400ms
}

body[data-aos-duration="450"] [data-aos], [data-aos][data-aos][data-aos-duration="450"] {
    transition-duration: 450ms
}

body[data-aos-delay="450"] [data-aos], [data-aos][data-aos][data-aos-delay="450"] {
    transition-delay: 0s
}

body[data-aos-delay="450"] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay="450"].aos-animate {
    transition-delay: 450ms
}

body[data-aos-duration="500"] [data-aos], [data-aos][data-aos][data-aos-duration="500"] {
    transition-duration: 500ms
}

body[data-aos-delay="500"] [data-aos], [data-aos][data-aos][data-aos-delay="500"] {
    transition-delay: 0s
}

body[data-aos-delay="500"] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay="500"].aos-animate {
    transition-delay: 500ms
}

body[data-aos-duration="550"] [data-aos], [data-aos][data-aos][data-aos-duration="550"] {
    transition-duration: 550ms
}

body[data-aos-delay="550"] [data-aos], [data-aos][data-aos][data-aos-delay="550"] {
    transition-delay: 0s
}

body[data-aos-delay="550"] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay="550"].aos-animate {
    transition-delay: 550ms
}

body[data-aos-duration="600"] [data-aos], [data-aos][data-aos][data-aos-duration="600"] {
    transition-duration: 600ms
}

body[data-aos-delay="600"] [data-aos], [data-aos][data-aos][data-aos-delay="600"] {
    transition-delay: 0s
}

body[data-aos-delay="600"] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay="600"].aos-animate {
    transition-delay: 600ms
}

body[data-aos-duration="650"] [data-aos], [data-aos][data-aos][data-aos-duration="650"] {
    transition-duration: 650ms
}

body[data-aos-delay="650"] [data-aos], [data-aos][data-aos][data-aos-delay="650"] {
    transition-delay: 0s
}

body[data-aos-delay="650"] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay="650"].aos-animate {
    transition-delay: 650ms
}

body[data-aos-duration="700"] [data-aos], [data-aos][data-aos][data-aos-duration="700"] {
    transition-duration: 700ms
}

body[data-aos-delay="700"] [data-aos], [data-aos][data-aos][data-aos-delay="700"] {
    transition-delay: 0s
}

body[data-aos-delay="700"] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay="700"].aos-animate {
    transition-delay: 700ms
}

body[data-aos-duration="750"] [data-aos], [data-aos][data-aos][data-aos-duration="750"] {
    transition-duration: 750ms
}

body[data-aos-delay="750"] [data-aos], [data-aos][data-aos][data-aos-delay="750"] {
    transition-delay: 0s
}

body[data-aos-delay="750"] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay="750"].aos-animate {
    transition-delay: 750ms
}

body[data-aos-duration="800"] [data-aos], [data-aos][data-aos][data-aos-duration="800"] {
    transition-duration: 800ms
}

body[data-aos-delay="800"] [data-aos], [data-aos][data-aos][data-aos-delay="800"] {
    transition-delay: 0s
}

body[data-aos-delay="800"] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay="800"].aos-animate {
    transition-delay: 800ms
}

body[data-aos-duration="850"] [data-aos], [data-aos][data-aos][data-aos-duration="850"] {
    transition-duration: 850ms
}

body[data-aos-delay="850"] [data-aos], [data-aos][data-aos][data-aos-delay="850"] {
    transition-delay: 0s
}

body[data-aos-delay="850"] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay="850"].aos-animate {
    transition-delay: 850ms
}

body[data-aos-duration="900"] [data-aos], [data-aos][data-aos][data-aos-duration="900"] {
    transition-duration: 900ms
}

body[data-aos-delay="900"] [data-aos], [data-aos][data-aos][data-aos-delay="900"] {
    transition-delay: 0s
}

body[data-aos-delay="900"] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay="900"].aos-animate {
    transition-delay: 900ms
}

body[data-aos-duration="950"] [data-aos], [data-aos][data-aos][data-aos-duration="950"] {
    transition-duration: 950ms
}

body[data-aos-delay="950"] [data-aos], [data-aos][data-aos][data-aos-delay="950"] {
    transition-delay: 0s
}

body[data-aos-delay="950"] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay="950"].aos-animate {
    transition-delay: 950ms
}

body[data-aos-duration="1000"] [data-aos], [data-aos][data-aos][data-aos-duration="1000"] {
    transition-duration: 1000ms
}

body[data-aos-delay="1000"] [data-aos], [data-aos][data-aos][data-aos-delay="1000"] {
    transition-delay: 0s
}

body[data-aos-delay="1000"] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay="1000"].aos-animate {
    transition-delay: 1000ms
}

body[data-aos-duration="1050"] [data-aos], [data-aos][data-aos][data-aos-duration="1050"] {
    transition-duration: 1050ms
}

body[data-aos-delay="1050"] [data-aos], [data-aos][data-aos][data-aos-delay="1050"] {
    transition-delay: 0s
}

body[data-aos-delay="1050"] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay="1050"].aos-animate {
    transition-delay: 1050ms
}

body[data-aos-duration="1100"] [data-aos], [data-aos][data-aos][data-aos-duration="1100"] {
    transition-duration: 1100ms
}

body[data-aos-delay="1100"] [data-aos], [data-aos][data-aos][data-aos-delay="1100"] {
    transition-delay: 0s
}

body[data-aos-delay="1100"] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay="1100"].aos-animate {
    transition-delay: 1100ms
}

body[data-aos-duration="1150"] [data-aos], [data-aos][data-aos][data-aos-duration="1150"] {
    transition-duration: 1150ms
}

body[data-aos-delay="1150"] [data-aos], [data-aos][data-aos][data-aos-delay="1150"] {
    transition-delay: 0s
}

body[data-aos-delay="1150"] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay="1150"].aos-animate {
    transition-delay: 1150ms
}

body[data-aos-duration="1200"] [data-aos], [data-aos][data-aos][data-aos-duration="1200"] {
    transition-duration: 1200ms
}

body[data-aos-delay="1200"] [data-aos], [data-aos][data-aos][data-aos-delay="1200"] {
    transition-delay: 0s
}

body[data-aos-delay="1200"] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay="1200"].aos-animate {
    transition-delay: 1200ms
}

body[data-aos-duration="1250"] [data-aos], [data-aos][data-aos][data-aos-duration="1250"] {
    transition-duration: 1250ms
}

body[data-aos-delay="1250"] [data-aos], [data-aos][data-aos][data-aos-delay="1250"] {
    transition-delay: 0s
}

body[data-aos-delay="1250"] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay="1250"].aos-animate {
    transition-delay: 1250ms
}

body[data-aos-duration="1300"] [data-aos], [data-aos][data-aos][data-aos-duration="1300"] {
    transition-duration: 1300ms
}

body[data-aos-delay="1300"] [data-aos], [data-aos][data-aos][data-aos-delay="1300"] {
    transition-delay: 0s
}

body[data-aos-delay="1300"] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay="1300"].aos-animate {
    transition-delay: 1300ms
}

body[data-aos-duration="1350"] [data-aos], [data-aos][data-aos][data-aos-duration="1350"] {
    transition-duration: 1350ms
}

body[data-aos-delay="1350"] [data-aos], [data-aos][data-aos][data-aos-delay="1350"] {
    transition-delay: 0s
}

body[data-aos-delay="1350"] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay="1350"].aos-animate {
    transition-delay: 1350ms
}

body[data-aos-duration="1400"] [data-aos], [data-aos][data-aos][data-aos-duration="1400"] {
    transition-duration: 1400ms
}

body[data-aos-delay="1400"] [data-aos], [data-aos][data-aos][data-aos-delay="1400"] {
    transition-delay: 0s
}

body[data-aos-delay="1400"] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay="1400"].aos-animate {
    transition-delay: 1400ms
}

body[data-aos-duration="1450"] [data-aos], [data-aos][data-aos][data-aos-duration="1450"] {
    transition-duration: 1450ms
}

body[data-aos-delay="1450"] [data-aos], [data-aos][data-aos][data-aos-delay="1450"] {
    transition-delay: 0s
}

body[data-aos-delay="1450"] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay="1450"].aos-animate {
    transition-delay: 1450ms
}

body[data-aos-duration="1500"] [data-aos], [data-aos][data-aos][data-aos-duration="1500"] {
    transition-duration: 1500ms
}

body[data-aos-delay="1500"] [data-aos], [data-aos][data-aos][data-aos-delay="1500"] {
    transition-delay: 0s
}

body[data-aos-delay="1500"] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay="1500"].aos-animate {
    transition-delay: 1500ms
}

body[data-aos-duration="1550"] [data-aos], [data-aos][data-aos][data-aos-duration="1550"] {
    transition-duration: 1550ms
}

body[data-aos-delay="1550"] [data-aos], [data-aos][data-aos][data-aos-delay="1550"] {
    transition-delay: 0s
}

body[data-aos-delay="1550"] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay="1550"].aos-animate {
    transition-delay: 1550ms
}

body[data-aos-duration="1600"] [data-aos], [data-aos][data-aos][data-aos-duration="1600"] {
    transition-duration: 1600ms
}

body[data-aos-delay="1600"] [data-aos], [data-aos][data-aos][data-aos-delay="1600"] {
    transition-delay: 0s
}

body[data-aos-delay="1600"] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay="1600"].aos-animate {
    transition-delay: 1600ms
}

body[data-aos-duration="1650"] [data-aos], [data-aos][data-aos][data-aos-duration="1650"] {
    transition-duration: 1650ms
}

body[data-aos-delay="1650"] [data-aos], [data-aos][data-aos][data-aos-delay="1650"] {
    transition-delay: 0s
}

body[data-aos-delay="1650"] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay="1650"].aos-animate {
    transition-delay: 1650ms
}

body[data-aos-duration="1700"] [data-aos], [data-aos][data-aos][data-aos-duration="1700"] {
    transition-duration: 1700ms
}

body[data-aos-delay="1700"] [data-aos], [data-aos][data-aos][data-aos-delay="1700"] {
    transition-delay: 0s
}

body[data-aos-delay="1700"] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay="1700"].aos-animate {
    transition-delay: 1700ms
}

body[data-aos-duration="1750"] [data-aos], [data-aos][data-aos][data-aos-duration="1750"] {
    transition-duration: 1750ms
}

body[data-aos-delay="1750"] [data-aos], [data-aos][data-aos][data-aos-delay="1750"] {
    transition-delay: 0s
}

body[data-aos-delay="1750"] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay="1750"].aos-animate {
    transition-delay: 1750ms
}

body[data-aos-duration="1800"] [data-aos], [data-aos][data-aos][data-aos-duration="1800"] {
    transition-duration: 1800ms
}

body[data-aos-delay="1800"] [data-aos], [data-aos][data-aos][data-aos-delay="1800"] {
    transition-delay: 0s
}

body[data-aos-delay="1800"] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay="1800"].aos-animate {
    transition-delay: 1800ms
}

body[data-aos-duration="1850"] [data-aos], [data-aos][data-aos][data-aos-duration="1850"] {
    transition-duration: 1850ms
}

body[data-aos-delay="1850"] [data-aos], [data-aos][data-aos][data-aos-delay="1850"] {
    transition-delay: 0s
}

body[data-aos-delay="1850"] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay="1850"].aos-animate {
    transition-delay: 1850ms
}

body[data-aos-duration="1900"] [data-aos], [data-aos][data-aos][data-aos-duration="1900"] {
    transition-duration: 1900ms
}

body[data-aos-delay="1900"] [data-aos], [data-aos][data-aos][data-aos-delay="1900"] {
    transition-delay: 0s
}

body[data-aos-delay="1900"] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay="1900"].aos-animate {
    transition-delay: 1900ms
}

body[data-aos-duration="1950"] [data-aos], [data-aos][data-aos][data-aos-duration="1950"] {
    transition-duration: 1950ms
}

body[data-aos-delay="1950"] [data-aos], [data-aos][data-aos][data-aos-delay="1950"] {
    transition-delay: 0s
}

body[data-aos-delay="1950"] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay="1950"].aos-animate {
    transition-delay: 1950ms
}

body[data-aos-duration="2000"] [data-aos], [data-aos][data-aos][data-aos-duration="2000"] {
    transition-duration: 2000ms
}

body[data-aos-delay="2000"] [data-aos], [data-aos][data-aos][data-aos-delay="2000"] {
    transition-delay: 0s
}

body[data-aos-delay="2000"] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay="2000"].aos-animate {
    transition-delay: 2000ms
}

body[data-aos-duration="2050"] [data-aos], [data-aos][data-aos][data-aos-duration="2050"] {
    transition-duration: 2050ms
}

body[data-aos-delay="2050"] [data-aos], [data-aos][data-aos][data-aos-delay="2050"] {
    transition-delay: 0s
}

body[data-aos-delay="2050"] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay="2050"].aos-animate {
    transition-delay: 2050ms
}

body[data-aos-duration="2100"] [data-aos], [data-aos][data-aos][data-aos-duration="2100"] {
    transition-duration: 2100ms
}

body[data-aos-delay="2100"] [data-aos], [data-aos][data-aos][data-aos-delay="2100"] {
    transition-delay: 0s
}

body[data-aos-delay="2100"] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay="2100"].aos-animate {
    transition-delay: 2100ms
}

body[data-aos-duration="2150"] [data-aos], [data-aos][data-aos][data-aos-duration="2150"] {
    transition-duration: 2150ms
}

body[data-aos-delay="2150"] [data-aos], [data-aos][data-aos][data-aos-delay="2150"] {
    transition-delay: 0s
}

body[data-aos-delay="2150"] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay="2150"].aos-animate {
    transition-delay: 2150ms
}

body[data-aos-duration="2200"] [data-aos], [data-aos][data-aos][data-aos-duration="2200"] {
    transition-duration: 2200ms
}

body[data-aos-delay="2200"] [data-aos], [data-aos][data-aos][data-aos-delay="2200"] {
    transition-delay: 0s
}

body[data-aos-delay="2200"] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay="2200"].aos-animate {
    transition-delay: 2200ms
}

body[data-aos-duration="2250"] [data-aos], [data-aos][data-aos][data-aos-duration="2250"] {
    transition-duration: 2250ms
}

body[data-aos-delay="2250"] [data-aos], [data-aos][data-aos][data-aos-delay="2250"] {
    transition-delay: 0s
}

body[data-aos-delay="2250"] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay="2250"].aos-animate {
    transition-delay: 2250ms
}

body[data-aos-duration="2300"] [data-aos], [data-aos][data-aos][data-aos-duration="2300"] {
    transition-duration: 2300ms
}

body[data-aos-delay="2300"] [data-aos], [data-aos][data-aos][data-aos-delay="2300"] {
    transition-delay: 0s
}

body[data-aos-delay="2300"] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay="2300"].aos-animate {
    transition-delay: 2300ms
}

body[data-aos-duration="2350"] [data-aos], [data-aos][data-aos][data-aos-duration="2350"] {
    transition-duration: 2350ms
}

body[data-aos-delay="2350"] [data-aos], [data-aos][data-aos][data-aos-delay="2350"] {
    transition-delay: 0s
}

body[data-aos-delay="2350"] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay="2350"].aos-animate {
    transition-delay: 2350ms
}

body[data-aos-duration="2400"] [data-aos], [data-aos][data-aos][data-aos-duration="2400"] {
    transition-duration: 2400ms
}

body[data-aos-delay="2400"] [data-aos], [data-aos][data-aos][data-aos-delay="2400"] {
    transition-delay: 0s
}

body[data-aos-delay="2400"] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay="2400"].aos-animate {
    transition-delay: 2400ms
}

body[data-aos-duration="2450"] [data-aos], [data-aos][data-aos][data-aos-duration="2450"] {
    transition-duration: 2450ms
}

body[data-aos-delay="2450"] [data-aos], [data-aos][data-aos][data-aos-delay="2450"] {
    transition-delay: 0s
}

body[data-aos-delay="2450"] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay="2450"].aos-animate {
    transition-delay: 2450ms
}

body[data-aos-duration="2500"] [data-aos], [data-aos][data-aos][data-aos-duration="2500"] {
    transition-duration: 2500ms
}

body[data-aos-delay="2500"] [data-aos], [data-aos][data-aos][data-aos-delay="2500"] {
    transition-delay: 0s
}

body[data-aos-delay="2500"] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay="2500"].aos-animate {
    transition-delay: 2500ms
}

body[data-aos-duration="2550"] [data-aos], [data-aos][data-aos][data-aos-duration="2550"] {
    transition-duration: 2550ms
}

body[data-aos-delay="2550"] [data-aos], [data-aos][data-aos][data-aos-delay="2550"] {
    transition-delay: 0s
}

body[data-aos-delay="2550"] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay="2550"].aos-animate {
    transition-delay: 2550ms
}

body[data-aos-duration="2600"] [data-aos], [data-aos][data-aos][data-aos-duration="2600"] {
    transition-duration: 2600ms
}

body[data-aos-delay="2600"] [data-aos], [data-aos][data-aos][data-aos-delay="2600"] {
    transition-delay: 0s
}

body[data-aos-delay="2600"] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay="2600"].aos-animate {
    transition-delay: 2600ms
}

body[data-aos-duration="2650"] [data-aos], [data-aos][data-aos][data-aos-duration="2650"] {
    transition-duration: 2650ms
}

body[data-aos-delay="2650"] [data-aos], [data-aos][data-aos][data-aos-delay="2650"] {
    transition-delay: 0s
}

body[data-aos-delay="2650"] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay="2650"].aos-animate {
    transition-delay: 2650ms
}

body[data-aos-duration="2700"] [data-aos], [data-aos][data-aos][data-aos-duration="2700"] {
    transition-duration: 2700ms
}

body[data-aos-delay="2700"] [data-aos], [data-aos][data-aos][data-aos-delay="2700"] {
    transition-delay: 0s
}

body[data-aos-delay="2700"] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay="2700"].aos-animate {
    transition-delay: 2700ms
}

body[data-aos-duration="2750"] [data-aos], [data-aos][data-aos][data-aos-duration="2750"] {
    transition-duration: 2750ms
}

body[data-aos-delay="2750"] [data-aos], [data-aos][data-aos][data-aos-delay="2750"] {
    transition-delay: 0s
}

body[data-aos-delay="2750"] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay="2750"].aos-animate {
    transition-delay: 2750ms
}

body[data-aos-duration="2800"] [data-aos], [data-aos][data-aos][data-aos-duration="2800"] {
    transition-duration: 2800ms
}

body[data-aos-delay="2800"] [data-aos], [data-aos][data-aos][data-aos-delay="2800"] {
    transition-delay: 0s
}

body[data-aos-delay="2800"] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay="2800"].aos-animate {
    transition-delay: 2800ms
}

body[data-aos-duration="2850"] [data-aos], [data-aos][data-aos][data-aos-duration="2850"] {
    transition-duration: 2850ms
}

body[data-aos-delay="2850"] [data-aos], [data-aos][data-aos][data-aos-delay="2850"] {
    transition-delay: 0s
}

body[data-aos-delay="2850"] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay="2850"].aos-animate {
    transition-delay: 2850ms
}

body[data-aos-duration="2900"] [data-aos], [data-aos][data-aos][data-aos-duration="2900"] {
    transition-duration: 2900ms
}

body[data-aos-delay="2900"] [data-aos], [data-aos][data-aos][data-aos-delay="2900"] {
    transition-delay: 0s
}

body[data-aos-delay="2900"] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay="2900"].aos-animate {
    transition-delay: 2900ms
}

body[data-aos-duration="2950"] [data-aos], [data-aos][data-aos][data-aos-duration="2950"] {
    transition-duration: 2950ms
}

body[data-aos-delay="2950"] [data-aos], [data-aos][data-aos][data-aos-delay="2950"] {
    transition-delay: 0s
}

body[data-aos-delay="2950"] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay="2950"].aos-animate {
    transition-delay: 2950ms
}

body[data-aos-duration="3000"] [data-aos], [data-aos][data-aos][data-aos-duration="3000"] {
    transition-duration: 3000ms
}

body[data-aos-delay="3000"] [data-aos], [data-aos][data-aos][data-aos-delay="3000"] {
    transition-delay: 0s
}

body[data-aos-delay="3000"] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay="3000"].aos-animate {
    transition-delay: 3000ms
}

[data-aos] {
    pointer-events: none
}

[data-aos].aos-animate {
    pointer-events: auto
}

body[data-aos-easing=linear] [data-aos], [data-aos][data-aos][data-aos-easing=linear] {
    transition-timing-function: cubic-bezier(0.25, 0.25, 0.75, 0.75)
}

body[data-aos-easing=ease] [data-aos], [data-aos][data-aos][data-aos-easing=ease] {
    transition-timing-function: cubic-bezier(0.25, 0.1, 0.25, 1)
}

body[data-aos-easing=ease-in] [data-aos], [data-aos][data-aos][data-aos-easing=ease-in] {
    transition-timing-function: cubic-bezier(0.42, 0, 1, 1)
}

body[data-aos-easing=ease-out] [data-aos], [data-aos][data-aos][data-aos-easing=ease-out] {
    transition-timing-function: cubic-bezier(0, 0, 0.58, 1)
}

body[data-aos-easing=ease-in-out] [data-aos], [data-aos][data-aos][data-aos-easing=ease-in-out] {
    transition-timing-function: cubic-bezier(0.42, 0, 0.58, 1)
}

body[data-aos-easing=ease-in-back] [data-aos], [data-aos][data-aos][data-aos-easing=ease-in-back] {
    transition-timing-function: cubic-bezier(0.6, -0.28, 0.735, 0.045)
}

body[data-aos-easing=ease-out-back] [data-aos], [data-aos][data-aos][data-aos-easing=ease-out-back] {
    transition-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1.275)
}

body[data-aos-easing=ease-in-out-back] [data-aos], [data-aos][data-aos][data-aos-easing=ease-in-out-back] {
    transition-timing-function: cubic-bezier(0.68, -0.55, 0.265, 1.55)
}

body[data-aos-easing=ease-in-sine] [data-aos], [data-aos][data-aos][data-aos-easing=ease-in-sine] {
    transition-timing-function: cubic-bezier(0.47, 0, 0.745, 0.715)
}

body[data-aos-easing=ease-out-sine] [data-aos], [data-aos][data-aos][data-aos-easing=ease-out-sine] {
    transition-timing-function: cubic-bezier(0.39, 0.575, 0.565, 1)
}

body[data-aos-easing=ease-in-out-sine] [data-aos], [data-aos][data-aos][data-aos-easing=ease-in-out-sine] {
    transition-timing-function: cubic-bezier(0.445, 0.05, 0.55, 0.95)
}

body[data-aos-easing=ease-in-quad] [data-aos], [data-aos][data-aos][data-aos-easing=ease-in-quad] {
    transition-timing-function: cubic-bezier(0.55, 0.085, 0.68, 0.53)
}

body[data-aos-easing=ease-out-quad] [data-aos], [data-aos][data-aos][data-aos-easing=ease-out-quad] {
    transition-timing-function: cubic-bezier(0.25, 0.46, 0.45, 0.94)
}

body[data-aos-easing=ease-in-out-quad] [data-aos], [data-aos][data-aos][data-aos-easing=ease-in-out-quad] {
    transition-timing-function: cubic-bezier(0.455, 0.03, 0.515, 0.955)
}

body[data-aos-easing=ease-in-cubic] [data-aos], [data-aos][data-aos][data-aos-easing=ease-in-cubic] {
    transition-timing-function: cubic-bezier(0.55, 0.085, 0.68, 0.53)
}

body[data-aos-easing=ease-out-cubic] [data-aos], [data-aos][data-aos][data-aos-easing=ease-out-cubic] {
    transition-timing-function: cubic-bezier(0.25, 0.46, 0.45, 0.94)
}

body[data-aos-easing=ease-in-out-cubic] [data-aos], [data-aos][data-aos][data-aos-easing=ease-in-out-cubic] {
    transition-timing-function: cubic-bezier(0.455, 0.03, 0.515, 0.955)
}

body[data-aos-easing=ease-in-quart] [data-aos], [data-aos][data-aos][data-aos-easing=ease-in-quart] {
    transition-timing-function: cubic-bezier(0.55, 0.085, 0.68, 0.53)
}

body[data-aos-easing=ease-out-quart] [data-aos], [data-aos][data-aos][data-aos-easing=ease-out-quart] {
    transition-timing-function: cubic-bezier(0.25, 0.46, 0.45, 0.94)
}

body[data-aos-easing=ease-in-out-quart] [data-aos], [data-aos][data-aos][data-aos-easing=ease-in-out-quart] {
    transition-timing-function: cubic-bezier(0.455, 0.03, 0.515, 0.955)
}



@media screen {
    html:not(.no-js) [data-aos^=fade][data-aos^=fade] {
        opacity: 0;
        transition-property: opacity, transform
    }

    html:not(.no-js) [data-aos^=fade][data-aos^=fade].aos-animate {
        opacity: 1;
        transform: none
    }

    html:not(.no-js) [data-aos=fade-up] {
        transform: translate3d(0, var(--aos-distance), 0)
    }

    html:not(.no-js) [data-aos=fade-down] {
        transform: translate3d(0, calc(-1 * var(--aos-distance)), 0)
    }

    html:not(.no-js) [data-aos=fade-right] {
        transform: translate3d(calc(-1 * var(--aos-distance)), 0, 0)
    }

    html:not(.no-js) [data-aos=fade-left] {
        transform: translate3d(var(--aos-distance), 0, 0)
    }

    html:not(.no-js) [data-aos=fade-up-right] {
        transform: translate3d(calc(-1 * var(--aos-distance)), var(--aos-distance), 0)
    }

    html:not(.no-js) [data-aos=fade-up-left] {
        transform: translate3d(var(--aos-distance), var(--aos-distance), 0)
    }

    html:not(.no-js) [data-aos=fade-down-right] {
        transform: translate3d(calc(-1 * var(--aos-distance)), calc(-1 * var(--aos-distance)), 0)
    }

    html:not(.no-js) [data-aos=fade-down-left] {
        transform: translate3d(var(--aos-distance), calc(-1 * var(--aos-distance)), 0)
    }

    html:not(.no-js) [data-aos^=zoom][data-aos^=zoom] {
        opacity: 0;
        transition-property: opacity, transform
    }

    html:not(.no-js) [data-aos^=zoom][data-aos^=zoom].aos-animate {
        opacity: 1;
        transform: translate3d(0, 0, 0) scale(1)
    }

    html:not(.no-js) [data-aos=zoom-in] {
        transform: scale(0.6)
    }

    html:not(.no-js) [data-aos=zoom-in-up] {
        transform: translate3d(0, var(--aos-distance), 0) scale(0.6)
    }

    html:not(.no-js) [data-aos=zoom-in-down] {
        transform: translate3d(0, calc(-1 * var(--aos-distance)), 0) scale(0.6)
    }

    html:not(.no-js) [data-aos=zoom-in-right] {
        transform: translate3d(calc(-1 * var(--aos-distance)), 0, 0) scale(0.6)
    }

    html:not(.no-js) [data-aos=zoom-in-left] {
        transform: translate3d(var(--aos-distance), 0, 0) scale(0.6)
    }

    html:not(.no-js) [data-aos=zoom-out] {
        transform: scale(1.2)
    }

    html:not(.no-js) [data-aos=zoom-out-up] {
        transform: translate3d(0, var(--aos-distance), 0) scale(1.2)
    }

    html:not(.no-js) [data-aos=zoom-out-down] {
        transform: translate3d(0, calc(-1 * var(--aos-distance)), 0) scale(1.2)
    }

    html:not(.no-js) [data-aos=zoom-out-right] {
        transform: translate3d(calc(-1 * var(--aos-distance)), 0, 0) scale(1.2)
    }

    html:not(.no-js) [data-aos=zoom-out-left] {
        transform: translate3d(var(--aos-distance), 0, 0) scale(1.2)
    }

    html:not(.no-js) [data-aos^=slide][data-aos^=slide] {
        transition-property: transform;
        visibility: hidden
    }

    html:not(.no-js) [data-aos^=slide][data-aos^=slide].aos-animate {
        visibility: visible;
        transform: translate3d(0, 0, 0)
    }

    html:not(.no-js) [data-aos=slide-up] {
        transform: translate3d(0, 100%, 0)
    }

    html:not(.no-js) [data-aos=slide-down] {
        transform: translate3d(0, -100%, 0)
    }

    html:not(.no-js) [data-aos=slide-right] {
        transform: translate3d(-100%, 0, 0)
    }

    html:not(.no-js) [data-aos=slide-left] {
        transform: translate3d(100%, 0, 0)
    }

    html:not(.no-js) [data-aos^=flip][data-aos^=flip] {
        backface-visibility: hidden;
        transition-property: transform
    }

    html:not(.no-js) [data-aos=flip-left] {
        transform: perspective(2500px) rotateY(-100deg)
    }

    html:not(.no-js) [data-aos=flip-left].aos-animate {
        transform: perspective(2500px) rotateY(0)
    }

    html:not(.no-js) [data-aos=flip-right] {
        transform: perspective(2500px) rotateY(100deg)
    }

    html:not(.no-js) [data-aos=flip-right].aos-animate {
        transform: perspective(2500px) rotateY(0)
    }

    html:not(.no-js) [data-aos=flip-up] {
        transform: perspective(2500px) rotateX(-100deg)
    }

    html:not(.no-js) [data-aos=flip-up].aos-animate {
        transform: perspective(2500px) rotateX(0)
    }

    html:not(.no-js) [data-aos=flip-down] {
        transform: perspective(2500px) rotateX(100deg)
    }

    html:not(.no-js) [data-aos=flip-down].aos-animate {
        transform: perspective(2500px) rotateX(0)
    }
}

/*# sourceMappingURL=aos.css.map */
